import React, {useContext} from "react";
import TurnRouter from "./TurnRouter";
import Navbar from "./components/Navbar";
const Turns = () => {
    return (<>
        <TurnRouter/>
    </>);
};

export default Turns;
